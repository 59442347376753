@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

@font-face {
	font-family: 'Compacta';
	src: url('../font/unicode.compacta-3.woff2') format('truetype');
	font-style: normal;
	font-weight: normal;
}

/*HEADING VARIABLES*/
h1 {
	font-size: $primary-font-size;
	color: $color-white;
	font-family: $primary-font-family;
}

h2 {
	font-size: $primary-font-size;
	color: $color-white;
	font-family: 'Compacta';
	font-weight: 400;
}

h3 {
	font-size: 36px;
	font-family: 'Compacta';
	font-weight: 400;
	margin: 16px;
}

h4 {
	font-size: 38px;
	font-weight: 400;
	margin: 0;
	font-family: 'Compacta';
}

h5 {
	font-size: 15px;
	margin: 0;
	font-weight: 400;
}

html {
	overflow-x: hidden;
}

body {
	color: $color-white;
	display: flex;
	flex-direction: column;
	background-image:
		url('../img/bg/PF-BG-15.png'),
		url('../img/bg/PG-BG-12.png');
	background-size: cover, cover;
	background-repeat: no-repeat, repeat;
	background-position: top center, 100% center;
	height: 100vh;
	font-size: 16px;
	line-height: 1;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media (max-width: 500px) {
	body {
		background-image: url('../img/bg/PF-BG-mobile-2.png'), url('../img/bg/PG-BG-12.png');
		background-size: cover, cover;
		background-repeat: no-repeat, repeat;
		background-position: top center, 100% center;
	}

}

/*BUTTON VARIABLES*/
a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

.primary-button {
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 8%;
	padding-right: 8%;
	border: 1px solid #000000;
	background: $gradient;
	font-family: $primary-font-family;
	font-weight: 700;
	font-size: $button-font-size;
	max-width: 100%;
	transition: 0.4s ease-in-out;
}


.primary-button:hover {
	background: linear-gradient(to top, #4E2B5B 10%, #311B39 100%);
	border: 1px solid #FFFFFF;
	transition: 0.4s ease-in-out;
}


.secondary-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20vw;
	aspect-ratio: 1 / 1;
	background-color: rgba(7, 2, 11, 0.6);
	padding: 1rem;
	box-sizing: border-box;
}

.secondary-button:hover {
	background-color: rgba(7, 2, 11, 0.7);
}


@media (max-width: 768px) {
	.secondary-button {
		width: 30vw;
		padding: 1rem;
	}
}

@media (max-width: 520px) {
	.secondary-button {
		width: 20vw;
		padding: 1rem;
	}
}


/*ALBUM-MAIN-SECTION*/
#main-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.main-text-container {
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 2%;
	margin-bottom: 3%;
	margin-left: 10%;
	margin-right: 10%;
	white-space: nowrap;
}

#top-text {
	margin-top: 68%;
	margin-bottom: 3%;
}



@media (max-width: 1500px) {
	.main-text-container {
		gap: 8px;
	}

}

@media (max-width: 900px) {
	h1 {
		font-size: 36px;
	}

}

@media (max-width: 696px) {
	h1 {
		font-size: 28px;
	}
}

@media (max-width: 530px) {
	h1 {
		font-size: 24px;
	}

	.main-text-container {
		margin-bottom: 7%;
	}
}

@media (max-width: 500px) {
	#top-text.main-text-container {
		margin-top: 175%;
	}
}

@media (max-width: 480px) {
	h1 {
		font-size: 20px;
	}
}

@media (max-width: 375px) {
	h1 {
		font-size: 16px;
	}
}

/*VIDEO SECTION*/
#video-section {
	margin-top: 7%;
	display: flex;
	justify-content: center;
}

iframe {
	border: 4px solid #24BFD3;
}

@media (max-width: 900px) {
	iframe {
		width: 550px;
		height: 325px;
	}
}

@media (max-width: 520px) {
	iframe {
		width: 425px;
		height: 250px;
		margin-left: 2%;
		margin-right: 2%;
	}
}

/*BUTTON SECTION*/
#button-section {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	gap: 3%;
	margin-top: 12%;
	margin-right: 10%;
	margin-left: 10%;
}

@media (max-width: 520px) {
	#button-section {
		flex-wrap: wrap;
		gap: 24px 16px;
		margin-bottom: 5%;
		margin-top: 15%;
	}
}


/*LISTEN + FOLLOW SECTION*/
#listen-section,
#follow-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	margin-top: 8%;

}

.listen-buttons,
.follow-buttons {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	align-items: center;
}

.social-button {
	width: 6vw;
	aspect-ratio: 1 / 1;
}

.social-button:hover {
	background-color: #1F092D;
}

.social-button-2 {
	width: 3vw;
}

.social-button-2 #tiktok,
#facebook {
	width: 2.4vw;
}

@media (max-width: 520px) {
	.social-button {
		width: 10vw;
	}

	.social-button-2 {
		width: 6vw;
	}

	.social-button-2 #tiktok,
	#facebook {
		width: 5vw;
	}

	#listen-section,
	#follow-section {
		margin-top: 7%;
		margin-bottom: 7%;
	}
}

/*LOGO SECTION*/
#logo-section {
	margin-top: 10%;
	padding-bottom: 12%;
	display: flex;
	justify-content: center;
}

.sony-logos {
	width: 32vw;
}

@media (max-width: 520px) {
	.sony-logos {
		width: 50vw;
	}

}


/*FOOTER*/
.copyright {
	font-size: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 4px;
	flex-wrap: wrap;
	margin-left: 2%;
	margin-right: 2%;
	letter-spacing: 2px;
	margin-bottom: 8px;

}

/*MODAL*/
.modal {
	display: none;
	position: fixed;
	inset: 0;
	z-index: 1000;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.modal.is-open {
	display: block;
	opacity: 1;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	will-change: opacity;
}

.modal-container {
	padding: 30px;
	height: 70vh;
	width: 450px;
	border-radius: 4px;
	margin: 0 auto;
	position: relative;
	background-image: url('../img/bg/PF-modal-bg.png');
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	transform: translateZ(0);
	margin-left: 16px;
	margin-right: 16px;
}

.modal-container header.modal-header {
	position: absolute;
	top: 10px;
	right: 10px;
}

.modal-content {
	display: flex;
	flex-direction: column;
	gap: 0;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 20px;
}

.modal-form-group input[type="email"] {
	background-color: rgba(20, 2, 35, 0.9);
	border: 2px solid #9f0048;
	width: 300px;
	padding: 10px 16px;
	text-align: center;
	font-weight: 600;
}

.modal-form-group input[type="email"]::placeholder {
	color: #f1f1f1;
	text-align: left;
	font-size: 18px;
	font-family: $primary-font-family;
	font-weight: 600;
	text-align: center;
}

.modal-form-group input[type="text"]::placeholder {
	color: #f1f1f1;
	text-align: left;
	font-size: 14px;
	font-family: $primary-font-family;
	font-weight: 600;
}

.modal-form-group input[type="text"] {
	background-color: rgba(20, 2, 35, 0.9);
	border: 2px solid #9f0048;
	width: 300px;
	padding: 6px 16px;
	margin: 2px;
}

.modal-form-group input[type="tel"]::placeholder {
	color: #f1f1f1;
	text-align: left;
	font-size: 14px;
	font-family: $primary-font-family;
	font-weight: 600;
}

.modal-form-group input[type="tel"] {
	background-color: rgba(20, 2, 35, 0.9);
	border: 2px solid #9f0048;
	width: 300px;
	padding: 6px 16px;
	margin: 4px;
}


select {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: rgba(20, 2, 35, 0.9);
	border: 2px solid #9f0048;
	width: 300px;
	font-size: 14px;
	font-family: $primary-font-family;
	font-weight: 600;
	padding: 6px 16px;
	margin: 4px;
	color: #f1f1f1;
}

.modal-form-group input {
	color: #f1f1f1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: $primary-font-family;
	font-size: 16px;
}

.modal-form-group {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}


#subscribe-button,
#continue-button {
	background-color: #9f0048;
	border: 1px solid #000000;
	margin-top: 8px;
	color: #f1f1f1;
	width: 200px;
	padding: 10px 16px;
	font-size: 22px;
	font-family: 'Compacta';
	padding-top: 13px;
}

.modal-close {
	background-image: url('../img/icon/modal-close.svg');
	width: 32px;
	height: 32px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: block;
}

.modal-footer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: -16px;
}

.error-message {
	color: $color-white;
	font-size: 16px;
	margin-top: 8px;
	font-family: $primary-font-family;
	text-align: center;
	padding-top: 4px;
	padding-bottom: 4px;
	border-radius: 2px;
}

.success-message {
	font-family: 'Compacta';
	font-size: 36px;
	margin-top: 12px;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 300px;
	background-color: #9f0048;
}

.required-field {
	color: FFFFFF;
	font-size: 14px;
	margin-top: 12px;
	font-family: $primary-font-family;
	text-align: center;
	margin-top: 24px;
}

.modal-logo-png {
	height: 30vh;
	position: absolute;
	top: 2%;
	left: 50%;
	transform: translate(-50%);
	box-sizing: border-box;
	margin-bottom: 12px;
}

.modal-content h3 {
	margin-top: 10px;
}

.second-header {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 16px;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -40%);
	width: 365px;
	text-align: center;
	align-items: center;
}

.additional-fields {
	margin-top: 16px;
}


h5 {
	width: 320px;
	text-align: center;
}

@media (max-height: 800px) {

	.modal-container {
		height: 75vh;
		margin-top: 25px;
		margin-bottom: 25px;
	}

	.modal-logo-png {
		height: 25vh;
	}

	.second-header {
		position: absolute;
		top: 40%;
	}

}

@media (max-height: 750px) {
	h4 {
		font-size: 30px;
	}

	h5 {
		font-size: 12px;
	}

	.second-header {
		position: absolute;
		top: 37%;
	}


}

@media (max-height: 650px) {
	.modal-logo-png {
		display: none;
	}

	h4 {
		font-size: 30px;
	}

	h5 {
		font-size: 12px;
	}

	.second-header {
		position: absolute;
		top: 20%;
	}


}

@media (max-height: 537px) {
	.second-header {
		position: absolute;
		top: 13%;
	}

}

@media (max-height: 470px) {

	.second-header {
		display: none;
	}
}