$fa-font-path: "../font";
$html-font-size: 16;

/*FONT FAMILIES*/
$primary-font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS';
$secondary-font-family: 'Compacta', sans-serif;

/*FONT SIZE VARIABLES*/
$primary-font-size: clamp(24px, 5vw, 56px);
$secondary-font-size: clamp(24px, 5vw, 32px);
$button-font-size: clamp(14px, 2vw, 32px);

/*COLORS*/
$color-white: #fff;
$color-black: #000000;
$gradient: linear-gradient(to top, #25BBCD 10%, #227680 100%);